<template>
  <div>
    <Home></Home>
  </div>
</template>

<script>
import Home from "@/components/Home/Home.vue";

export default {
  components: {
    Home,
  },
};
</script>
