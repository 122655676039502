import { mapState } from "vuex";
import dayjs from "dayjs";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data: () => ({
    isLoading: true,
    fullPage: true,
    payment_type: 0,
    mall_name: null,
    mall_manager: null,
    mall_phone: null,
    mall_email: null,
    total_order_count: 0,
    order_list: [],
    page: 1,
    max_page_count: 5,
    page_count: 1,
    total_page_count: 1,
    plus_page_count: 0,
    offset: 0,
    limit: 10,
    order_no: 1,
    right_display: false,
    left_display: false,
  }),
  methods: {
    getOrderType: async function () {
      await this.$store.dispatch("payment/getOrderType");
    },
    getOrderLatest: async function () {
      await this.$store.dispatch("payment/getOrderLatest");
    },
    getOrderList: async function () {
      const params = {
        offset: this.offset,
        limit: this.limit,
      };
      await this.$store.dispatch("payment/getOrderList", params);
    },
    setOrderList: function () {
      this.total_order_count = this.payment_list.payments_count;
      this.order_list = this.payment_list.payments;
    },
    pageing: function () {
      //총 페이지 수
      this.total_page_count = Math.ceil(this.total_order_count / this.limit);
      this.order_no = this.total_order_count - this.offset;

      // 총페이지 수가 노출할 최대 버튼 수보다 크면 최대 버튼개수 노출
      if (this.total_page_count > this.max_page_count) {
        this.page_count = this.max_page_count;
        this.right_display = true;
      } else {
        this.page_count = this.total_page_count;
      }
    },
    pageChange: async function (page_no) {
      this.page = page_no;
      this.offset = (this.page - 1) * this.limit;
      await this.getOrderList();
      await this.setOrderList();
      this.order_no = this.total_order_count - this.offset;
    },
    postOrders: async function () {
      if (!this.payment_mall_info.mall_name) {
        alert("쇼핑몰명을 입력해주세요.");
        return;
      }
      if (!this.payment_mall_info.mall_manager) {
        alert("담당자명을 입력해주세요.");
        return;
      }
      if (!this.payment_mall_info.mall_phone) {
        alert("핸드폰번호를 입력해주세요.");
        return;
      }
      if (!this.payment_mall_info.mall_email) {
        alert("이메일주소를 입력해주세요.");
        return;
      }

      let is_phone_number = this.isPhoneNumber(this.payment_mall_info.mall_phone);
      if (!is_phone_number || this.payment_mall_info.mall_phone.length >= 12) {
        alert("핸드폰 번호를 확인해주세요.");
        return;
      }

      let is_mail = this.isMail(this.payment_mall_info.mall_email);
      if (!is_mail) {
        alert("이메일주소를 확인해주세요.");
        return;
      }

      let type = Number(this.payment_type);
      if (type == 0) {
        alert("개월 수를 선택해주세요.");
        return;
      }

      const params = {
        mall_order_type: this.payment_type,
        mall_name: this.payment_mall_info.mall_name,
        mall_manager: this.payment_mall_info.mall_manager,
        mall_phone: this.payment_mall_info.mall_phone,
        mall_email: this.payment_mall_info.mall_email,
      };
      const result = await this.$store.dispatch("payment/postOrders", params);
      if (result.confirmation_url) {
        window.location.href = result.confirmation_url;
      }
    },

    dateFormat: function (param) {
      return dayjs(param).format("YYYY-MM-DD");
    },

    isPhoneNumber: function (phone_number) {
      let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
      let result = regExp.test(phone_number);
      return result;
    },
    isMail: function (mail) {
      let regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      let result = regExp.test(mail);
      return result;
    },
    replaceNumber: function (e, max, chkNumber = false) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9]/g, "").substr(0, max - 1);
      e.target.value = chkNumber === true ? Number(replaceVal) : replaceVal;
      if (chkNumber === true && e.target.value == 0) {
        e.target.value = "";
      }
      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    left_page: function () {
      this.plus_page_count = this.plus_page_count - this.max_page_count;
      if (this.plus_page_count < 1) {
        this.plus_page_count = 0;
        this.page = 1;
        this.left_display = false;
      }
      // 총페이지 수가 노출할 최대 버튼 수보다 크면 최대 버튼개수 노출
      if (this.total_page_count > this.max_page_count) {
        this.right_display = true;
      }
      let _show_btn = this.page - this.max_page_count;
      if (_show_btn <= 1) {
        this.page = 1;
      } else {
        this.page = _show_btn;
      }

      let _page_count = this.total_page_count - this.max_page_count;
      if (_page_count < this.max_page_count) {
        this.page_count = _page_count;
      } else {
        this.page_count = this.max_page_count;
      }
    },
    right_page: function () {
      this.left_display = true;
      this.plus_page_count = this.plus_page_count + this.max_page_count;
      this.page = this.page + this.max_page_count;

      let _page_count = this.total_page_count - this.max_page_count;
      if (_page_count < this.max_page_count) {
        this.page_count = _page_count;
        this.right_display = false;
      }

      if (_page_count <= this.plus_page_count) {
        this.right_display = false;
        this.page_count = _page_count - this.max_page_count;
      }
    },
    getExpire: async function () {
      await this.$store.dispatch("payment/getExpire");
    },
  },
  computed: {
    ...mapState("payment", ["payment_type_list", "payment_list", "payment_mall_info"]),
  },
  components: {
    Loading,
  },
  created: async function () {
    await this.getOrderType();
    await this.getOrderLatest();
    await this.getOrderList();

    this.setOrderList();
    await this.pageing();

    await this.getExpire();

    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  },
};
