import dayjs from "dayjs";

const mutations = {
  setOrderType(state, payload) {
    state.payment_type_list = payload;
  },
  setOrderList(state, payload) {
    state.payment_list = payload;
  },
  setOrderLatest(state, payload) {
    state.payment_mall_info = payload;
  },
  setExpire(state, payload) {
    state.expire = payload;

    if (payload.is_expired === false) {
      let today = dayjs();
      let expired_at = dayjs(payload.expired_at);
      let result = expired_at.diff(today, "day", true);
      state.expire.days = Math.floor(result);
    } else {
      state.expire.days = 0;
    }
  },
};

export default mutations;
