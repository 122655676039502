<template>
  <div class="sub_category">
    <div class="sub_category_tit">질문관리</div>
    <ul>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 1 } }" class="router_link"><li>Q1 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 2 } }" class="router_link"><li>Q2 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 3 } }" class="router_link"><li>Q3 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 4 } }" class="router_link"><li>Q4 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 5 } }" class="router_link"><li>Q5 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 6 } }" class="router_link"><li>Q6 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 7 } }" class="router_link"><li>Q7 설정</li></router-link>
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 8 } }" class="router_link"><li>Q8 설정</li></router-link>
    </ul>
  </div>
</template>
