import { createRouter, createWebHistory } from "vue-router";

import App from "@/App.vue";

import Whooroyalart from "@/Whooroyalart.vue";

// 홈
import Home from "../views/Home/Home.vue";

// 결제관리
import Payment from "../views/Payment/Payment.vue";

// 질문화면
import Question from "../views/SurveyStatistics/Question/Question.vue";

// 결과화면
import Result from "../views/SurveyStatistics/Result/Result.vue";

const routes = [
  {
    path: "/",
    name: "BaseUrl",
    redirect: {
      name: "SurveyStatistics",
    },
  },
  {
    path: "/whooroyalart/init",
    name: "Init",
    redirect: {
      name: "SurveyStatistics",
    },
  },
  {
    path: "/whooroyalart",
    redirect: "/whooroyalart/home",
    name: "SurveyStatistics",
    component: Whooroyalart,
    children: [
      {
        path: "/whooroyalart/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/whooroyalart/payment",
        name: "Payment",
        component: Payment,
      },
    ],
  },
  {
    path: "/survey/payment",
    redirect: {
      name: "Payment",
    },
  },
  {
    path: "/survey",
    redirect: "/survey/question",
    name: "SurveyAdmin",
    component: App,
    children: [
      // 질문화면
      {
        path: "question",
        name: "Question",
        component: Question,
      },
      // 결과화면
      {
        path: "result",
        name: "Result",
        component: Result,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
