import SurveyCategory from "@/components/Common/SurveyCategory.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data: () => ({
    parent_hierarchy_id: null,
    isLoading: false,
    fullPage: true,
    display_none: "display: none;",
    subject: null,
    question_list: [],
    parent_question_list: [
      {
        hierarchy_id: 1,
        parent_id: 0,
        depth: 0,
        subject: "Q1 설정",
        children: [
          {
            sequence: 1,
            parent_id: 1,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 2,
        parent_id: 0,
        depth: 0,
        subject: "Q2 설정",
        children: [
          {
            sequence: 1,
            parent_id: 2,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 3,
        parent_id: 0,
        depth: 0,
        subject: "Q3 설정",
        children: [
          {
            sequence: 1,
            parent_id: 3,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 4,
        parent_id: 0,
        depth: 0,
        subject: "Q4 설정",
        children: [
          {
            sequence: 1,
            parent_id: 4,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 5,
        parent_id: 0,
        depth: 0,
        subject: "Q5 설정",
        children: [
          {
            sequence: 1,
            parent_id: 5,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 6,
        parent_id: 0,
        depth: 0,
        subject: "Q6 설정",
        children: [
          {
            sequence: 1,
            parent_id: 6,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
              {
                sequence: 3,
                depth: 2,
                subject: "",
              },
              {
                sequence: 4,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 7,
        parent_id: 0,
        depth: 0,
        subject: "Q7 설정",
        children: [
          {
            sequence: 1,
            parent_id: 7,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
      {
        hierarchy_id: 8,
        parent_id: 0,
        depth: 0,
        subject: "Q8 설정",
        children: [
          {
            sequence: 1,
            parent_id: 8,
            depth: 1,
            subject: "",
            children: [
              {
                sequence: 1,
                depth: 2,
                subject: "",
              },
              {
                sequence: 2,
                depth: 2,
                subject: "",
              },
              {
                sequence: 3,
                depth: 2,
                subject: "",
              },
              {
                sequence: 4,
                depth: 2,
                subject: "",
              },
            ],
          },
        ],
      },
    ],
  }),
  components: {
    SurveyCategory,
    Loading,
  },
  computed: {
    maxLimit: function () {
      return this.today;
    },
  },
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    sleep: async function (time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    getQuestion: async function () {
      const params = {
        parent_hierarchy_id: this.parent_hierarchy_id,
      };
      let result = await this.$store.dispatch("survey/getListHierarchy", params);
      console.log("result", result);
      // 조회 된게 없으면 디폴트값입력
      let parent_question = this.parent_question_list.find((e) => Number(e.hierarchy_id) == Number(this.parent_hierarchy_id));
      if (!result || result.length == 0) {
        result = [parent_question];
      } else {
        if (result[0].children[0].children.length < parent_question.children[0].children.length) {
          let count = parent_question.children[0].children.length - result[0].children[0].children.length;
          for (let i = 0; i < count; i++) {
            result[0].children[0].children.push({
              sequence: result[0].children.length + 1 + i,
              depth: 2,
              subject: "",
            });
          }
        }
      }
      console.log("result", result);
      return result;
    },
    setQuestion: function (parent_question) {
      console.log("parent_question", parent_question);
      this.question_list = [];
      this.subject = parent_question.subject;
      for (const question of parent_question.children) {
        this.question_list.push(question);
      }
    },
    saveQuestion: async function () {
      let index = this.parent_question_list.findIndex((e) => Number(e.hierarchy_id) == Number(this.parent_hierarchy_id));
      if (index > -1) {
        this.parent_question_list[index].children = this.question_list;
      } else {
        alert("정보가 잘못되었습니다.");
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("survey/postHierarchy", this.parent_question_list[index]);
      await this.sleep(500);
      this.isLoading = false;
      await this.sleep(200);
      alert("저장되었습니다.");
    },
    comma: function (value) {
      return value.toLocaleString("ko-KR");
    },
    chagePage: async function () {
      this.isLoading = true;
      window.scrollTo(0, 0);
      this.parent_hierarchy_id = this.$route.query.parent_hierarchy_id;
      let parent_question_list = await this.getQuestion();
      this.setQuestion(parent_question_list[0]);
      await this.sleep(500);
      this.isLoading = false;
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.reset();
    this.parent_hierarchy_id = this.$route.query.parent_hierarchy_id;
    let parent_question_list = await this.getQuestion();
    this.setQuestion(parent_question_list[0]);
    this.display_none = "";
  },
  watch: {
    $route(to, form) {
      if (to.name == form.name && to.fullPath !== form.fullPath) {
        this.chagePage();
      }
    },
  },
};
