import M from "@/libs/mapping.js";

const actions = {
  // 토큰재발급
  async getRefreshToken({ commit }) {
    try {
      const result = await M.api(M.entry("/get/auth/refresh"));
      commit("setToken", result);
    } catch (e) {
      // 토큰 재발급 실패시 처리 할 내용
    }
  },
  // 이미지 등록
  async postImage(commit, params) {
    const result = await M.api(M.entry("/post/survey/upload/image"), params);
    return result.image;
  },
};

export default actions;
