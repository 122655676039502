<template>
  <div class="admin_content_wrap" :style="display_none">
    <div class="admin_content">
      <div class="sub_wrap">
        <ResultCategory></ResultCategory>
        <!-- 서브페이지 시작 -->

        <div class="sub_page">
          <div class="sub_page_tit pb-5">{{ character_name }}</div>
          <div class="upload_table">
            <dl class="table_img">
              <dd>캐릭터 이미지</dd>
              <dd>
                <div class="file_input_div">
                  <input type="button" value=" + 이미지 등록" class="file_input_button" />
                  <input type="file" class="file_input_hidden" @change="changeCharacterImage" />
                </div>
                <input type="text" id="basic_icon" class="file_input_textbox" readonly="readonly" />
                <div class="img_upload_thum">
                  <img :src="character.character_image" alt="" class="" />
                </div>
              </dd>
            </dl>
            <dl class="table_text">
              <dd>설명문구</dd>
              <dd>
                <textarea v-model="character.character_description"></textarea>
              </dd>
            </dl>
            <dl>
              <dd>MBTI 태그</dd>
              <dd>
                <input type="text" v-model="character.mbti_tag" />
              </dd>
            </dl>
          </div>
          <div class="mt-6 mb-3">노출 상품 정보</div>
          <div class="upload_table">
            <dl class="table_img">
              <dd>매칭 상품 이미지</dd>
              <dd>
                <div class="file_input_div">
                  <input type="button" value=" + 이미지 등록" class="file_input_button" />
                  <input type="file" class="file_input_hidden" @change="changeMatchingProductImage" />
                </div>
                <input type="text" id="basic_icon" class="file_input_textbox" readonly="readonly" />
                <div class="img_upload_thum">
                  <img :src="character.matching_product_image" alt="" class="" />
                </div>
              </dd>
            </dl>
            <dl>
              <dd>매칭 상품 아이콘명</dd>
              <dd>
                <input type="text" v-model="character.matching_product_icon_name" />
              </dd>
            </dl>
            <dl>
              <dd>매칭 상품명</dd>
              <dd>
                <input type="text" v-model="character.matching_product_name" />
              </dd>
            </dl>
            <dl class="table_text">
              <dd>설명문구</dd>
              <dd>
                <textarea v-model="character.matching_product_description"></textarea>
              </dd>
            </dl>
            <dl>
              <dd>매칭 상품 링크</dd>
              <dd>
                <input type="text" v-model="character.matching_product_link" />
              </dd>
            </dl>
          </div>

          <div class="mt-6 mb-2">
            <div class="mt-6 mb-2 d-flex align-center">
              <div style="width: 120px">포장지 노출 정보</div>
              <div class="file_input_div d-flex align-center ml-2" style="width: 60px; margin-bottom: 0px">
                <input type="button" value="+ 추가" class="add_button" @click="addOption()" />
              </div>
              <div style="width: 700px" class="text-end">※ 최대 10개까지 등록 가능</div>
            </div>
          </div>
          <div class="upload_table">
            <div v-for="(option, index) in character.option_list" :key="index">
              <dl>
                <dd>
                  포장지명 {{ index + 1 }}
                  <input v-show="index > 0" type="button" value="삭제" class="delete_button" @click="deleteOption(index)" />
                </dd>
                <dd>
                  <input type="text" v-model="option.option_name" />
                </dd>
              </dl>
              <dl>
                <dd>옵션명 {{ index + 1 }}</dd>
                <dd>
                  <input type="text" v-model="option.add_option_name" />
                </dd>
              </dl>
              <dl class="table_text">
                <dd>설명문구 {{ index + 1 }}</dd>
                <dd>
                  <textarea v-model="option.option_description"></textarea>
                </dd>
              </dl>
              <dl class="table_img">
                <dd>포장지 이미지 {{ index + 1 }}</dd>
                <dd>
                  <div class="file_input_div">
                    <input type="button" value=" + 이미지 등록" class="file_input_button" />
                    <input type="file" class="file_input_hidden" @change="changeOptionProductImage($event, index)" />
                  </div>
                  <input type="text" id="basic_icon" class="file_input_textbox" readonly="readonly" />
                  <div class="img_upload_thum">
                    <img :src="option.option_image" alt="" class="" />
                  </div>
                </dd>
              </dl>
            </div>
          </div>

          <div class="mt-6 mb-3">유형별 궁합 정보</div>
          <div class="upload_table">
            <dl>
              <dd>BEST 캐릭터명</dd>
              <dd>
                <select v-model="character.best_character_id">
                  <option value="0">선택해주세요</option>
                  <option v-for="char of character_list" :value="char.value" :key="char.value">{{ char.text }}</option>
                </select>
              </dd>
            </dl>
            <dl>
              <dd>BEST 캐릭터설명</dd>
              <dd>
                <input type="text" v-model="character.best_character_description" />
              </dd>
            </dl>
            <dl>
              <dd>WORST 캐릭터명</dd>
              <dd>
                <select v-model="character.worst_character_id">
                  <option value="0">선택해주세요</option>
                  <option v-for="char of character_list" :value="char.value" :key="char.value">{{ char.text }}</option>
                </select>
              </dd>
            </dl>
            <dl>
              <dd>WORST 캐릭터설명</dd>
              <dd>
                <input type="text" v-model="character.worst_character_description" />
              </dd>
            </dl>
          </div>
          <div class="btn_upload">
            <button @click="saveResult()">저장</button>
          </div>
        </div>
        <!-- //서브페이지 끝 -->
      </div>
    </div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="fullPage" />
    </div>
  </div>
</template>

<style scope>
.add_button {
  padding: 4px 10px 5px;
  font-size: 12px !important;
  font-weight: 500;
  color: rgb(119, 119, 119) !important;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(204, 204, 204) !important;
  height: 30px;
}
.delete_button {
  padding: 4px 10px 5px;
  font-size: 12px !important;
  font-weight: 500;
  border: 1px solid rgb(204, 204, 204) !important;
  height: 30px !important;
  width: 50px !important;
  padding: 0 !important;
  float: right;
  margin-right: 10px;
  background: white;
}
</style>
<script src="./Result.js"></script>
