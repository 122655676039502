import actions from "./actions";
import mutations from "./mutations";

const state = {
  // 결제 타입 리스트
  payment_type_list: [],
  // 결제 리스트
  payment_list: [],
  // 어드민-결제-관리-입력-정보-조회
  payment_mall_info: {},
  // 만료일 정보
  expire: {},
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
