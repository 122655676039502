<template>
  <div class="admin_content_wrap" :style="display_none">
    <div class="admin_content">
      <div class="sub_wrap">
        <SurveyCategory></SurveyCategory>
        <!-- 서브페이지 시작 -->
        <div class="sub_page">
          <div class="sub_page_tit">{{ subject }}</div>

          <div v-for="question of question_list" :key="question.hierarchy_id">
            <div class="sub_page_question BTG">
              질문
              <textarea v-model="question.subject"></textarea>
            </div>

            <div v-for="(answer, index) of question.children" :key="answer.hierarchy_id">
              <div class="sub_page_question BTG">
                답변{{ index + 1 }}
                <textarea v-model="answer.subject"></textarea>
              </div>
            </div>
          </div>
          <div class="btn_upload">
            <button @click="saveQuestion()">저장</button>
          </div>
        </div>
        <!-- //서브페이지 끝 -->
      </div>
    </div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="fullPage" />
    </div>
  </div>
</template>

<script src="./Question.js"></script>
