export default Object.freeze({
  /**
   * 토큰 갱신
   */
  "/get/auth/refresh": {
    path: "/app/survey/cafe24/{1}/auth/refresh",
    type: "GET",
  },
  /**
   * 질문화면 조회
   */
  "/get/survey/hierarchy": {
    path: "/app/survey/mall/{1}/api/survey/hierarchy/{2}",
    type: "GET",
  },
  /**
   * 질문화면 등록, 수정
   */
  "/post/survey/hierarchy": {
    path: "/app/survey/mall/{1}/api/survey/hierarchy/{2}",
    type: "POST",
  },
  /**
   * 이미지 등록
   */
  "/post/survey/upload/image": {
    path: "/app/survey/mall/{1}/api/survey/upload/image",
    type: "POST",
  },
  /**
   * 결과화면 조회
   */
  "/get/result": {
    path: "/app/survey/mall/{1}/api/survey/result/{2}",
    type: "GET",
  },

  /**
   * 결과화면 등록/수정
   */
  "/post/result": {
    path: "/app/survey/mall/{1}/api/survey/result/{2}",
    type: "POST",
  },

  /**
   * 어드민-결제-타입리스트-조회
   */
  "/get/orders/types": {
    path: "/app/survey/mall/{1}/api/orders/types",
    type: "GET",
  },
  /**
   * 어드민-결제-리스트-조회
   */
  "/get/payments": {
    path: "/app/survey/mall/{1}/api/payments",
    type: "GET",
  },
  /**
   * 어드민-결제-관리-입력-정보-조회
   */
  "/get/orders/latest": {
    path: "/app/survey/mall/{1}/api/orders/latest",
    type: "GET",
  },
  /**
   * 어드민-결제
   */
  "/post/orders": {
    path: "/app/survey/mall/{1}/api/orders",
    type: "POST",
  },
  /**
   * 어드민-만료일-조회
   */
  "/get/survey/expire": {
    path: "/app/survey/mall/{1}/api/survey/expire",
    type: "GET",
  },
});
