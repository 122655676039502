<template>
  <div class="ma-8 home-width">
    <div>
      <p class="title mt-7 mb-3">설정/관리 어드민 바로가기</p>

      <div class="v-col-6 pa-0 home-width">
        <v-card outlined>
          <v-col class="d-flex align-center">
            <p class="ma-0 v-col-10">
              [LG생활건강 맞춤질문] 설정/관리 어드민 접속을 위해 <br />
              우측의 '바로가기' 버튼을 클릭해주세요😀
            </p>
            <div class="text-white v-col-2 pa-0 align-end pr-3">
              <v-btn color="#1976d2" hide-details flat size="large" class="v-col-12" @click="adminMove()">바로가기</v-btn>
            </div>
          </v-col>
        </v-card>
      </div>
    </div>

    <div>
      <p class="title mt-7 mb-3">샵플러스 고객센터</p>

      <div class="v-col-6 pa-0 home-width">
        <v-card outlined>
          <v-col class="d-flex align-center">
            <p class="ma-0 v-col-10">
              궁금하시거나 문제 발생시에 게시판 또는 고객센터로 연락주세요. <br />
              친절하고 빠르게 처리하도록 하겠습니다. <br />
              👉 0505-277-7770
            </p>
            <div class="text-white v-col-2 pa-0 align-end pr-3">
              <v-btn color="#424242" hide-details flat size="large" class="v-col-12 text-white" @click="inquiry()">문의하기</v-btn>
            </div>
          </v-col>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}

.home-width {
  min-width: 800px;
}
</style>

<script src="./Home.js"></script>
