<template>
  <div>
    <Header></Header>
    <Question ref="Result"></Question>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import Question from "@/components/SurveyStatistics/Result/Result.vue";
import Footer from "@/views/Footer.vue";

export default {
  components: {
    Header,
    Question,
    Footer,
  },
};
</script>
