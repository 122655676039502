import { mapState } from "vuex";

export default {
  data: () => ({
    //
  }),

  computed: {
    ...mapState("common", ["mall_id", "access_token"]),
  },
  methods: {
    comma: function (value) {
      return value.toLocaleString("ko-KR");
    },
    gerReferrerDomain: function () {
      return document.referrer.split("/")[2];
    },
    inflowPath() {
      const _domain = this.gerReferrerDomain();
      if (_domain != "localhost:8080" && _domain != "whooroyalart.cafe24.shoplus.store") {
        // alert("잘못된 접근입니다.");
        // location.href = "https://eclogin.cafe24.com/Shop/";
        // return;
      }
    },
  },
  created: async function () {
    this.inflowPath();
  },
};
