import actions from "./actions";
import mutations from "./mutations";

const state = {
  // 계층 리스트
  hierarchy_list: [],
  // 계층 상세 정보
  hierarchy_detail: {},
  // 계층별 순위
  rank_hierarchy: {},
  // 분석 결과
  analysis: {
    again_click_count: 0,
    homepage_direct_click_count: 0,
    result_kakaotalk_click_count: 0,
    sign_up_coupon_click_count: 0,
  },
  // 결과전송 리스트
  sent_result_list: [],
  // 결과전송 총 개수
  sent_result_total_count: 0,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
