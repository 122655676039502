import M from "@/libs/mapping.js";

const actions = {
  // 결과화면-조회
  async getResult(commit, params) {
    try {
      return await M.api(M.entry("/get/result", params.character_id));
    } catch (e) {
      return {
        character_image: "",
        character_description: "",
        mbti_tag: "",
        matching_product_image: "",
        matching_product_icon_name: "",
        matching_product_name: "",
        matching_product_description: "",
        matching_product_link: "",
        option_list: [
          {
            sequence: "",
            option_name: "",
            option_description: "",
            option_image: "",
          },
          {
            sequence: "",
            option_name: "",
            option_description: "",
            option_image: "",
          },
        ],
        best_character_id: null,
        best_character_description: "",
        worst_character_id: null,
        worst_character_description: "",
      };
    }
  },
  // 결과화면-등록/수정 /post/products
  async postResult(commit, params) {
    try {
      const result = await M.api(M.entry("/post/result", params.character_id), params);
      if (result) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
};

export default actions;
