import M from "@/libs/mapping.js";

const actions = {
  // 계층 리스트 조회
  async getListHierarchy(commit, params) {
    try {
      return await M.api(M.entry("/get/survey/hierarchy", params.parent_hierarchy_id));
    } catch (e) {
      return [];
    }
  },

  // 계층 등록 수정
  async postHierarchy(commit, params) {
    try {
      const result = await M.api(M.entry("/post/survey/hierarchy", params.hierarchy_id), params);
      if (result) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
};

export default actions;
