<template>
  <div>
    <v-main class="ma-8 pa-0">
      <p class="title mt-0 mb-3">결제 관리</p>

      <p class="mb-0 subtitle-1 font-weight-light">호스팅서버/관리 관련 비용(월)을 결제하여 사용이 가능합니다.</p>
      <Payment></Payment>
    </v-main>
  </div>
</template>
<style scoped>
.title {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}
</style>
<script>
import Payment from "@/components/Payment/Payment.vue";

export default {
  components: {
    Payment,
  },
};
</script>
