import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import mutations from "./mutations";
import modules from "./modules";
import actions from "./actions";
import entries from "@/datas/entries.js";

export default createStore({
  state: {
    api_url: "https://survey-lg-api.shoplus.store",
    // api_url: "http://localhost:15800/",
    app_code: "survey-lg-ui",
    entries,
  },
  mutations,
  actions,
  getters: {},
  modules: {
    common: modules.common,
    survey: modules.survey,
    result: modules.result,
    payment: modules.payment,
  },
  plugins: [
    createPersistedState({
      paths: ["common", "survey", "result", "payment"],
    }),
  ],
});
