<template>
  <div class="sub_category">
    <div class="sub_category_tit">결과관리</div>
    <ul>
      <router-link :to="{ name: 'Result', query: { character_id: 1 } }" class="router_link"><li>수모 (메이크업 아티스트)</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 2 } }" class="router_link"><li>검무가</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 3 } }" class="router_link"><li>화백 (동양화)</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 4 } }" class="router_link"><li>궁중 무희</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 5 } }" class="router_link"><li>서예가</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 6 } }" class="router_link"><li>시인</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 7 } }" class="router_link"><li>명창 (판소리)</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 8 } }" class="router_link"><li>악공 (해금)</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 9 } }" class="router_link"><li>자수공예가</li></router-link>
      <router-link :to="{ name: 'Result', query: { character_id: 10 } }" class="router_link"><li>도예가</li></router-link>
    </ul>
  </div>
</template>
