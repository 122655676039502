<template>
  <div class="v-col-11 pl-0 ml-0 payment-width">
    <v-row>
      <v-col class="pb-0">
        <p class="title mt-11 mb-3">결제 하기</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="v-col-3">
        <input type="text" class="mr-3 px-3" placeholder="쇼핑몰명" v-model="payment_mall_info.mall_name" />
      </v-col>
      <v-col class="v-col-3">
        <input type="text" class="mr-3 px-3" placeholder="담당자명" v-model="payment_mall_info.mall_manager" />
      </v-col>
      <v-col class="v-col-3">
        <input
          type="text"
          class="mr-3 px-3"
          placeholder="핸드폰번호"
          v-model="payment_mall_info.mall_phone"
          @keydown="replaceNumber($event, 12, false)"
          @keyup="replaceNumber($event, 12, false)"
        />
      </v-col>
      <v-col class="v-col-3">
        <input type="text" class="mr-3 px-3" placeholder="이메일주소" v-model="payment_mall_info.mall_email" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="v-col-3">
        <select type="text" class="mr-3 px-3 select-text" placeholder="이메일주소" v-model="payment_type">
          <option value="0">맞춤질문 서비스 - 개월 수 선택</option>
          <option v-for="payment_type in payment_type_list" :key="payment_type.order_type" :value="payment_type.order_type">
            {{ payment_type.order_name }}
          </option>
        </select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end pt-0 pb-0 text-center">
        <v-btn large color="#1976d2" class="text-white" width="200" @click.prevent="postOrders()">결제하기</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0">
        <p class="title mt-11 mb-3">결제 내역</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pb-0">
        <v-sheet color="white" elevation="2" class="v-col-12">
          <div class="d-flex v-col-12 align-center text-center pa-0">
            <p class="v-col-2 data-subject pa-0">결제일시</p>
            <p class="v-col-4 data-subject pa-0">주문번호</p>
            <p class="v-col-2 data-subject pa-0">결제내용</p>
            <p class="v-col-2 data-subject pa-0">시작일</p>
            <p class="v-col-2 data-subject pa-0">종료일</p>
          </div>
          <div v-if="order_list.length == 0">
            <v-divider class="my-3"></v-divider>
            <div class="v-col-12 align-center text-center pa-2 text-grey font-weight-light no-data">조회된 결제 내역이 없습니다.</div>
          </div>

          <div v-for="order in order_list" :key="order.order_id">
            <v-divider class="my-3"></v-divider>
            <div class="d-flex v-col-12 align-center text-center pa-0">
              <p class="v-col-2 data-subject pa-0">{{ order.paid_date }}</p>
              <p class="v-col-4 data-subject pa-0">{{ order.order_id }}</p>
              <p class="v-col-2 data-subject pa-0">{{ order.order_name }}</p>
              <p class="v-col-2 data-subject pa-0">{{ dateFormat(order.start_date) }}</p>
              <p class="v-col-2 data-subject pa-0">{{ dateFormat(order.end_date) }}</p>
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col class="d-flex justify-center pt-0 pb-0 text-center">
        <v-btn v-if="left_display" color="#fff" class="text-black mr-2" size="x-small" width="30" height="30" @click="left_page()"
          ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
        >

        <div v-for="index in page_count" :key="index" @click="pageChange(index + plus_page_count)">
          <v-btn v-if="page == index + plus_page_count" color="#1976d2" class="text-white mr-2" size="x-small" width="30" height="30">{{
            index + plus_page_count
          }}</v-btn>
          <v-btn v-else color="#fff" class="text-black mr-2" size="x-small" width="30" height="30">{{ index + plus_page_count }}</v-btn>
        </div>
        <v-btn v-if="right_display" color="#fff" class="text-black mr-2" size="x-small" width="30" height="30" @click="right_page()"
          ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="fullPage" />
    </div>
  </div>
</template>
<style scoped>
input,
select {
  height: 40px;
  width: 100%;
  border-radius: 4px;
}

select {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ddd !important;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  color: grey;
  font-size: 16px !important;
  font-weight: 100 !important;
}

select option :first-child {
  color: black !important;
  font-size: 16px !important;
  font-weight: 100 !important;
}

.select-text {
  font-size: 16px !important;
  font-weight: 100 !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: url(https://whooroyalart.cafe24.com/web/survey/arrow.jpg) no-repeat 97% 50%/15px auto !important;
}

input::placeholder {
  font-style: "Noto Sans KR", sans-serif !important;
  font-size: 16px;
  font-weight: 100 !important;
  justify-content: center !important;
}

.data-subject {
  font-size: 14px !important;
  font-weight: 100 !important;
}

button,
input,
optgroup,
select,
textarea {
  font: 16px "Noto Sans KR" !important;
}

.title {
  font-size: 1.25rem !important;
  font-weight: 300;
  letter-spacing: normal !important;
}

.payment-width {
  min-width: 1100px;
}

.no-data {
  font-size: 15px;
}
</style>
<script src="./Payment.js"></script>
