import actions from "./actions";
import mutations from "./mutations";

const state = {
  mall_id: "",
  access_token: "",
  refresh_token: "",
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
