const mutations = {
  setMallId(state, mall_id) {
    state.mall_id = mall_id;
  },
  setToken(state, payload) {
    state.access_token = payload.access_token;
    state.refresh_token = payload.refresh_token;
  },
  setProductList(state, payload) {
    state.product_list = payload;
  },
};

export default mutations;
