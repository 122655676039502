import ResultCategory from "@/components/Common/ResultCategory.vue";
import FileReader from "@tanker/file-reader";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data: () => ({
    character_id: null,
    character_name: null,
    isLoading: false,
    fullPage: true,
    display_none: "display: none;",
    subject: null,
    question_list: [],
    character: {
      character_image: null,
      character_description: null,
      mbti_tag: null,
      matching_product_image: null,
      matching_product_icon_name: null,
      matching_product_name: null,
      matching_product_description: null,
      matching_product_link: null,
      option_list: [
        {
          sequence: null,
          option_name: null,
          option_description: null,
          option_image: null,
          add_option_name: null,
        },
      ],
      best_character_id: null,
      best_character_description: null,
      worst_character_id: null,
      worst_character_description: null,
    },

    character_list: [
      {
        text: "수모 (메이크업 아티스트)",
        value: 1,
      },
      {
        text: "검무가",
        value: 2,
      },
      {
        text: "화백 (동양화)",
        value: 3,
      },
      {
        text: "궁중 무희",
        value: 4,
      },
      {
        text: "서예가",
        value: 5,
      },
      {
        text: "시인",
        value: 6,
      },
      {
        text: "명창 (판소리)",
        value: 7,
      },
      {
        text: "악공 (해금)",
        value: 8,
      },
      {
        text: "자수공예가",
        value: 9,
      },
      {
        text: "도예가",
        value: 10,
      },
    ],
  }),
  components: {
    ResultCategory,
    Loading,
  },
  computed: {
    maxLimit: function () {
      return this.today;
    },
  },
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    sleep: async function (time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    getResult: async function () {
      const params = {
        character_id: this.character_id,
      };
      let result = await this.$store.dispatch("result/getResult", params);
      return result;
    },
    setResult: function (result) {
      let character_info = this.character_list.find((e) => Number(e.value) == Number(this.character_id));
      this.character_name = character_info.text;
      if (result.option_list.length == 0) {
        result.option_list.push({
          sequence: 1,
          option_name: null,
          option_description: null,
          option_image: null,
          add_option_name: null,
        });
      }
      this.character = result;
    },
    saveResult: async function () {
      for (const option of this.character.option_list) {
        if (!option.option_name || !option.option_description || !option.option_image || !option.add_option_name) {
          alert("포장지 노출 정보를 확인해주세요.");
          return;
        }
      }
      this.isLoading = true;

      if (this.character.character_image.indexOf("data:image") > -1) {
        const image = {
          image: this.character.character_image,
        };
        this.character.character_image = await this.$store.dispatch("common/postImage", image);
      }
      if (this.character.matching_product_image.indexOf("data:image") > -1) {
        const image = {
          image: this.character.matching_product_image,
        };
        this.character.matching_product_image = await this.$store.dispatch("common/postImage", image);
      }

      for (const option of this.character.option_list) {
        if (option.option_image.indexOf("data:image") > -1) {
          const image = {
            image: option.option_image,
          };
          option.option_image = await this.$store.dispatch("common/postImage", image);
        }
      }

      this.character.character_id = this.character_id;
      await this.$store.dispatch("result/postResult", this.character);
      await this.sleep(500);
      this.isLoading = false;
      await this.sleep(100);
      alert("저장되었습니다.");
    },
    comma: function (value) {
      return value.toLocaleString("ko-KR");
    },
    changeCharacterImage: async function (event) {
      const reulst = await this.uploadImage(event.target.files[0]);
      this.character.character_image = reulst;
    },
    changeMatchingProductImage: async function (event) {
      const reulst = await this.uploadImage(event.target.files[0]);
      this.character.matching_product_image = reulst;
    },
    changeOptionProductImage: async function (event, index) {
      const reulst = await this.uploadImage(event.target.files[0]);
      this.character.option_list[index].option_image = reulst;
    },
    uploadImage: async function (file) {
      if (!file) {
        alert("이미지를 확인해주세요.");
        return false;
      }

      const reader = new FileReader(file);
      const dataUrl = await reader.readAsDataURL();
      return dataUrl;
    },
    chagePage: async function () {
      this.isLoading = true;
      window.scrollTo(0, 0);
      this.character_id = this.$route.query.character_id;
      let result = await this.getResult();
      this.setResult(result);
      await this.sleep(500);
      this.isLoading = false;
    },
    addOption: async function () {
      if (this.character.option_list.length >= 10) {
        alert("최대 10개까지 등록 가능합니다.");
        return;
      }
      let sequence = 1;
      for (const option of this.character.option_list) {
        option.sequence = sequence;
        sequence++;
      }

      this.character.option_list.push({
        sequence: sequence++,
        option_name: null,
        option_description: null,
        option_image: null,
        add_option_name: null,
      });
    },
    deleteOption: async function (index) {
      this.character.option_list.splice(index, 1);

      let sequence = 1;
      for (const option of this.character.option_list) {
        option.sequence = sequence;
        sequence++;
      }
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.reset();
    this.character_id = this.$route.query.character_id;
    let result = await this.getResult();
    this.setResult(result);
    this.display_none = "";
  },
  watch: {
    $route(to, form) {
      if (to.name == form.name && to.fullPath !== form.fullPath) {
        this.chagePage();
      }
    },
  },
};
