const mutations = {
  setListHierarchy(state, payload) {
    state.hierarchy_list[payload.parent_hierarchy_id] = payload.list;
  },
  setDetailHierarchy(state, payload) {
    state.hierarchy_detail = payload;
  },
  setRankHierarchy(state, payload) {
    state.rank_hierarchy[payload.hierarchy_id] = payload.list;
  },
  setAnalysis(state, payload) {
    if (payload.length == 0) {
      state.analysis.again_click_count = 0;
      state.analysis.homepage_direct_click_count = 0;
      state.analysis.result_kakaotalk_click_count = 0;
      state.analysis.sign_up_coupon_click_count = 0;
    } else {
      for (let key in payload) {
        for (let _key in payload[key]) {
          if (_key == "again_click_count") {
            state.analysis.again_click_count = payload[key].again_click_count;
          }
          if (_key == "homepage_direct_click_count") {
            state.analysis.homepage_direct_click_count = payload[key].homepage_direct_click_count;
          }
          if (_key == "result_kakaotalk_click_count") {
            state.analysis.result_kakaotalk_click_count = payload[key].result_kakaotalk_click_count;
          }
          if (_key == "sign_up_coupon_click_count") {
            state.analysis.sign_up_coupon_click_count = payload[key].sign_up_coupon_click_count;
          }
        }
      }
    }
  },
  setRankHierarchyProducts(state, payload) {
    state.rank_hierarchy_products = payload;
  },
  setSentResultList(state, payload) {
    state.sent_result_total_count = payload.total_count;
    state.sent_result_list = payload.list;
  },
};

export default mutations;
