<template>
  <v-app id="inspire" @click="closeServiceModal()">
    <div>
      <v-navigation-drawer v-model="drawer" app ref="appNavigation" outlined :mobile="true" absolute>
        <v-list dense>
          <template v-for="item in items" :key="item.text">
            <v-list-item link>
              <router-link class="navigation-router-link" :to="item.toPath">
                <v-list-item class="">
                  <v-icon class="menu_icon">{{ item.icon }}</v-icon>
                </v-list-item>

                <v-list-item>
                  <p class="subtitle-2 mb-0 menu_text">{{ item.text }}</p>
                </v-list-item>
              </router-link>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app color="#1565c0" dark ref="appBar" class="app-bar-width">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title style="width: 500px" class="ml-0 pl-4">
          <router-link :to="{ path: '/' }" class="hidden-sm-and-down">
            <span class="hidden-sm-and-down hover top-text" @click="moveHome()" color="white">{{ appTitle }}</span>
          </router-link>
        </v-toolbar-title>
        <v-spacer />

        <div class="d-flex align-center">
          <router-link :to="{ path: '/whooroyalart/payment' }" class="service-top-text">
            <p class="mb-0 mr-5 mt-0 top-text hidden-xs-only service-top-text hover" style="text-align: end">
              서비스 사용중 ( D-day {{ expire.days }} )
            </p>
          </router-link>
          <v-btn icon @click.stop="showService()">
            <v-icon color="white">mdi-apps</v-icon>
          </v-btn>
        </div>
      </v-app-bar>

      <v-main class="height" fluid>
        <router-view></router-view>
      </v-main>
    </div>

    <v-sheet
      v-show="service_modal"
      color="white"
      elevation="3"
      outlined
      width="450"
      class="d-felx sheet-scroll"
      absolute
      right="1%"
      top="8%"
    >
      <div class="text-center">
        <v-menu>
          <v-list class="sheet-scroll">
            <v-list-item
              class="hover list-item"
              @click="cafe24SiteShow('https://store.cafe24.com/apps/10777')"
              style="text-decoration: none !important"
            >
              <div class="d-flex align-center">
                <div>
                  <div tile size="80" color="grey" class="image mr-4 mt-2 mb-2">
                    <img src="https://cert-product.shoplus.store/authservice/etc/service_images/survey_icon.jpg" />
                  </div>
                </div>
                <div>
                  <div class="font-weight-bold body-1 black--text mb-0 d-flex">
                    <p class="mb-1 service-title" style="line-height: 1.8">맞춤 질문 추천 서비스</p>
                    <p class="mb-1 justify-end">
                      <v-icon>mdi-chevron-right</v-icon>
                    </p>
                  </div>
                  <div class="descript">
                    고객 취향에 맞춘 개인화 상품 추천서비스.<br />
                    고객이 선택한 답변에 따라 상품을 추천하므로<br />
                    구매율과 만족도를 높여줍니다.<br />
                  </div>
                </div>
              </div>
            </v-list-item>
            <!--
            <v-list-item
              class="hover list-item"
              @click="cafe24SiteShow('https://store.cafe24.com/apps/8510')"
              style="text-decoration: none !important"
            >
              <div class="d-flex align-center">
                <div>
                  <div tile size="80" color="grey" class="image mr-4 mt-2 mb-2">
                    <img src="https://cert-product.shoplus.store/authservice/etc/service_images/point_sales_service.jpg" />
                  </div>
                </div>
                <div>
                  <div class="font-weight-bold body-1 black--text mb-0 d-flex">
                    <p class="mb-1 service-title" style="line-height: 1.8">포인트 판매 서비스</p>
                    <p class="mb-1 justify-end">
                      <v-icon>mdi-chevron-right</v-icon>
                    </p>
                  </div>
                  <div class="descript">
                    포인트를 판매하고 적립금을 자동 지급하는 서비스.<br />
                    회원들은 적립금 혜택으로 만족하고 남은 적립금은<br />
                    재방문/재구매를 이끌어줍니다.
                  </div>
                </div>
              </div>
            </v-list-item>
            -->
            <v-list-item
              class="hover list-item"
              @click="cafe24SiteShow('https://store.cafe24.com/apps/6711')"
              style="text-decoration: none !important"
            >
              <div class="d-flex align-center">
                <div>
                  <div tile size="80" color="grey" class="image mr-4 mt-2 mb-2">
                    <img src="https://cert-product.shoplus.store/authservice/etc/service_images/membership.jpg" />
                  </div>
                </div>
                <div>
                  <div class="font-weight-bold body-1 black--text mb-0 d-flex">
                    <p class="mb-1 service-title" style="line-height: 1.8">유료 멤버십 서비스</p>
                    <p class="mb-1 justify-end">
                      <v-icon>mdi-chevron-right</v-icon>
                    </p>
                  </div>
                  <div class="descript">
                    쇼핑몰 회원들에게 제공되는 차별화된 서비스.<br />
                    쇼핑몰 구독시대에 필요한 특별한 서비스로<br />
                    고객 이탈을 막고 충성고객을 확보하세요.
                  </div>
                </div>
              </div>
            </v-list-item>
            <v-list-item
              class="hover list-item"
              @click="cafe24SiteShow('https://store.cafe24.com/apps/4238')"
              style="text-decoration: none !important"
            >
              <div class="d-flex align-center">
                <div>
                  <div tile size="80" color="grey" class="image mr-4 mt-2 mb-2">
                    <img src="https://cert-product.shoplus.store/authservice/etc/service_images/auth-code-service-icon.jpg" />
                  </div>
                </div>
                <div>
                  <div class="font-weight-bold body-1 black--text mb-0 d-flex">
                    <p class="mb-1 service-title" style="line-height: 1.8">정품 인증 서비스</p>
                    <p class="mb-1 justify-end">
                      <v-icon>mdi-chevron-right</v-icon>
                    </p>
                  </div>
                  <div class="descript">
                    회원가입 및 브랜드 가치를 높여주는 서비스.<br />
                    정품 인증 회원에게 다양한 프로모션을 제공하고<br />
                    충성 고객으로 만들어보세요!
                  </div>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-sheet>
  </v-app>
</template>

<style>
.v-main__wrap > .v-application > .v-application__wrap > .v-main > .v-main__wrap {
  background: #fbf6e3;
}
textarea,
input {
  font-weight: normal;
}
</style>
<style scoped>
.navigation-router-link {
  color: unset;
  text-decoration: none;
  width: 100%;
  display: flex;
}

.hover {
  cursor: pointer;
  text-decoration: none;
}
.hidden-sm-and-down.hover:hover {
  text-decoration: aliceblue;
  text-decoration-skip-ink: none;
  text-decoration: underline;
}

.v-app-bar-nav-icon >>> i {
  color: white !important;
}

.top-text {
  color: white !important;
  font-size: 1.25rem;
}

.service-top-text {
  color: white !important;
  font-size: 16px;
}
.service-top-text :hover {
  color: white !important;
  font-size: 16px;
  text-decoration: aliceblue !important;
  text-decoration-skip-ink: none;
  text-decoration: underline !important;
}
.top-text :hover {
  text-decoration: aliceblue;
  text-decoration-skip-ink: none;
  text-decoration: underline;
}

.sheet-scroll {
  overflow-y: hidden;
}

.service-title {
  font-weight: 500;
  width: 300px;
  text-align: left;
}

.descript {
  font-size: 0.875rem;
  font-weight: lighter;
  text-align: left;
  line-height: 1.2;
}

.image {
  height: 80px;
  min-width: 80px;
  width: 80px;
}

.list-item {
  width: 100%;
}

.app-bar-width {
  margin: 0px !important;
  width: 100% !important;
}

.v-navigation-drawer {
  z-index: 2 !important;
  top: 62px;
  position: fixed !important;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  props: {
    source: String,
  },
  data() {
    return {
      appTitle: "The history of Whoo",
      drawer: null,
      unPlus: {
        color: "#8C8C8C",
      },
      items: [
        {
          icon: "mdi-home",
          text: "홈",
          toPath: `/`,
        },
        {
          icon: "mdi-credit-card-outline",
          text: "결제 관리",
          toPath: `/whooroyalart/payment`,
        },
      ],
      service_modal: false,
      drawer_modal: false,
      d_day: 0,
    };
  },

  methods: {
    cafe24SiteShow: function (v) {
      window.open(v, "_blank");
    },
    showService: function () {
      this.service_modal = !this.service_modal;
    },

    closeServiceModal: function () {
      this.service_modal = false;
    },
  },
  computed: {
    ...mapState("payment", ["expire"]),
  },
  created: function () {},
};
</script>
