import { mapState } from "vuex";

export default {
  data: () => ({
    is_use_disabled: true,
  }),
  methods: {
    setToken: function () {
      if (this.$route.query.access_token) {
        const set_token_params = {
          access_token: this.$route.query.access_token,
          refresh_token: null,
        };

        this.$store.commit("common/setToken", set_token_params);
      }
    },
    setMallId: function () {
      if (this.$route.query.mall_id) {
        this.$store.commit("common/setMallId", this.$route.query.mall_id);
      }
    },
    adminMove: function () {
      // if (this.is_use_disabled) {
      //   alert("서비스 이용기간이 만료되었습니다.\n결제 후 이용해주세요.");
      //   this.$router.push({ path: "/whooroyalart/payment" });
      //   return;
      // }
      window.open("/survey/question?parent_hierarchy_id=1");
    },
    inquiry: function () {
      window.open("https://www.shoplus.biz/");
    },
    getExpire: async function () {
      await this.$store.dispatch("payment/getExpire");
    },
    isUseService: function () {
      this.is_use_disabled = this.expire.is_expired;
    },
  },
  computed: {
    ...mapState("common", ["mall_id", "access_token"]),
    ...mapState("payment", ["expire"]),
  },
  created: async function () {
    // token 설정
    this.setToken();
    // mall_id 설정
    this.setMallId();
    //만료일 조회
    await this.getExpire();
    // 이용 가능 여부 체크
    this.isUseService();
  },
};
