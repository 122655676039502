<template>
  <!-- header -->
  <div class="admin_header_wrap">
    <div class="admin_header">
      <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 1 } }" class=""
        ><img src="//thewhoo.com/skin-mobile1/img/main/main_logo_v2.png" alt="로고"
      /></router-link>
      <ul>
        <li>
          <router-link :to="{ name: 'Question', query: { parent_hierarchy_id: 1 } }" class="">질문화면</router-link
          ><a href="/survey/basicInfo"></a>
        </li>
        <li><router-link :to="{ name: 'Result', query: { character_id: 1 } }" class="">결과화면</router-link></li>
      </ul>
    </div>
  </div>
</template>
<script src="./Header.js"></script>
