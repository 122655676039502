export default class APIEntry {
  constructor(path, method, target) {
    this.path = path;
    this.method = method;
    this.target = target;
  }

  getPath() {
    return this.path;
  }

  getMethod() {
    return this.method;
  }
}
