import M from "@/libs/mapping.js";

const actions = {
  // 결제 타입 리스트 조회
  async getOrderType({ commit }) {
    const result = await M.api(M.entry("/get/orders/types"));
    commit("setOrderType", result);
  },

  // 결제 리스트
  async getOrderList({ commit }, params) {
    const result = await M.api(M.entry("/get/payments"), params);
    commit("setOrderList", result);
  },

  // 결제 관리 입력 정보 조회
  async getOrderLatest({ commit }) {
    const result = await M.api(M.entry("/get/orders/latest"));
    commit("setOrderLatest", result);
  },

  // 어드민 결제
  async postOrders(commit, params) {
    return await M.api(M.entry("/post/orders"), params);
  },

  async getExpire({ commit }) {
    const result = await M.api(M.entry("/get/survey/expire"));
    commit("setExpire", result);
  },
};

export default actions;
